<template>
      <div class="Register-box">
        <header>
          <h1>Create an account Reachmybox</h1>
        </header>
        <section>
          <form
            @submit.prevent="submitForm"
            class="register-form"
          >
            <div class="input-group">
              <label for="Email">Email</label>
              <input
                id="email"
                type="email"
                required
                placeholder="Your Email"
                v-model.trim="email"
              >
            </div>
            <div class="input-group">
              <label for="password">Password</label>
              <input
                id="password"
                type="password"
                required
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                title="Minimum eight characters and at least :
                one uppercase letter,
                one lowercase letter,
                one number,
                one special character"
                placeholder="Password"
                v-model.trim="password"
              >
            </div>
            <div class="input-group">
              <label for="repeatpassword">Repeat Password</label>
              <input
                id="repeatpassword"
                type="password"
                required
                :pattern="password"
                title="need to be same than password"
                placeholder="Repeat Password"
                v-model.trim="passwordTest"
              >
            </div>
            <span v-if="!isFormValid">{{formErrorMessage}}</span>
            <div class="input-group">
              <button>Register</button>
            </div>
          </form>
        </section>
        <footer>
          <div class="footerText">
            Have an account ?
          </div>
          <router-link to="/login">
          Sign In
          </router-link>
        </footer>
      </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useAuth } from '@/composable/auth.js';

export default {
  setup() {
    const router = useRouter();
    const isFormValid = ref(true);
    const formErrorMessage = ref('');
    const email = ref('');
    const password = ref('');
    const passwordTest = ref('');
    const { signUp } = useAuth();

    async function submitForm() {
      isFormValid.value = true;

      if (email.value === ''
        || !email.value.includes('@')
        || password.value.length < 8
        || password.value !== passwordTest.value
      ) {
        isFormValid.value = false;
        if (email.value === '') {
          formErrorMessage.value = 'You need to add an email adresse !';
        }
        if (password.value.length < 8) {
          formErrorMessage.value = 'Password need to be more than 8 char !';
        }
        if (password.value !== passwordTest.value) {
          formErrorMessage.value = 'Password are different !';
        }
      }
      if (isFormValid.value) {
        await signUp(email.value, password.value)
          .then(() => router.push('/home'))
          .catch((error) => {
            isFormValid.value = false;
            formErrorMessage.value = error;
          });
      }
    }

    return {
      isFormValid,
      email,
      password,
      passwordTest,
      submitForm,
      formErrorMessage
    };
  },
};
</script>
